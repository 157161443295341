import { useCallback } from "react"
import { validateLinkToken } from "../../api/validateLinkToken"
import { sendLinkTokenValid } from "../../messages"
import { useLinkStore } from "../../stores/linkStore"
import { useLiveSessionStore } from "../../stores/liveSessionStore"
import { usePageRouterStore } from "../../stores/pageRouterStore"

export const useLinkValidation = () => {
	const { setLinkData } = useLinkStore()
	const { setEstablishConnection } = useLiveSessionStore()
	const { setPage } = usePageRouterStore()

	const verifyLinkToken = useCallback(
		async (linkToken: string) => {
			try {
				const data = await validateLinkToken(linkToken)
				if (data.status === "ok") {
					sendLinkTokenValid()
					setLinkData(data.linkData)
					if (data.isConnected) {
						setPage("authenticated")
						return
					}
					if (!data.linkData) {
						setPage("failure")
						return
					}
					
					if (!data.linkData.basicAuth) {
						setEstablishConnection(true)
					}

				}
			} catch (error) {
				console.error("Failed to verify link token:", error)
			}
		},
		[setLinkData, setEstablishConnection, setPage],
	)

	return { verifyLinkToken }
}

import { memo, useState } from "react";
import NavigationBar from "../../components/Navigation/NavigationBar";
import { useLiveSessionStore } from "../../stores/liveSessionStore";

const LiveSessionComponent = memo(({ url, loading }: { url?: string, loading: boolean } ) => {
  if (loading || !url) {
      return (
          <div className="absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%]">
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="animate-spin"
              >
                  <path d="M21 12a9 9 0 1 1-6.219-8.56" />
              </svg>
          </div>
      );
  }

  return (
      <iframe
          title="authentication portal"
          src={`${url}&navbar=false`}
          sandbox="allow-same-origin allow-scripts"
          allow="clipboard-read; clipboard-write"
          className="w-full h-full rounded-b-2xl"
      />
  );
});

export default function AuthenticatePage() {
  const { url, loading } = useLiveSessionStore()
  const [iframeLoaded, setIframeLoaded] = useState(false)

  const LoadingSpinner = () => (
    <div className="absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%]">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="animate-spin"
      >
        <path d="M21 12a9 9 0 1 1-6.219-8.56" />
      </svg>
    </div>
  )

  return (
    <div className="fade-in h-full flex flex-col">
      <div className="px-6 pt-6">
        <NavigationBar backPage="disclosure" />
      </div>
      <div className="mt-1 w-full h-auto grow relative"><LiveSessionComponent url={url} loading={loading} /></div>
    </div>
  )
}
